<script>
import { required, email } from "vuelidate/lib/validators";
import { Icon } from "@iconify/vue2";
import axios from "axios";
import Loader from "@/components/loader";

export default {
  components: { Icon, Loader },
  data() {
    return {
      email: "test@example.com",
      password: "password",
      submitted: false,
      isLogin: false,
      isMessage: "",
      isError: false,
    };
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  created() {
    this.ifLogin()
  },
  methods: {
    ifLogin(){
      if(JSON.parse(localStorage.getItem("user"))){
        this.$router.push("/");
      }
    },
    login() {
      this.isLogin = true;
      const api_url = process.env.VUE_APP_BASEURL + "/login";
      const data = {
        email: this.email,
        password: this.password
      };
      axios
        .post(api_url, data)
        .then((response) => {
          const res = JSON.stringify(response.data);
          localStorage.setItem("user", res);
          this.$router.push("/");
        })
        .catch((error) => {
          this.isMessage = error.response.data.message
          this.isError = true
        })
        .finally(() => {
          this.isLogin = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <Loader v-if="isLogin === true" />
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <Icon icon="lucide:home" class="text-white h2" />
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo.png" height="150" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                        <p class="text-muted">Sign in to continue to RTS.</p>
                      </div>
                      <b-alert v-if="isError" show variant="danger">{{ this.isMessage }}</b-alert>
                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="login">
                          <div class="form-group auth-form-group-custom mb-4">
                            <Icon icon="lucide:mail" class="auti-custom-input-icon" />
                            <label for="email">Email</label>
                            <input type="email" v-model="email" class="form-control" id="email" placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }" />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">Mail adresi zorunludur.</span>
                              <span v-if="!$v.email.email">Şifre zorunludur.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <Icon icon="lucide:lock" class="auti-custom-input-icon" />
                            <label for="userpassword">Password</label>
                            <input v-model="password" type="password" class="form-control" id="userpassword"
                              placeholder="Enter password" :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }" />
                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                              Password is required.
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light" type="submit"
                              v-bind:disabled="isLogin">
                              Giriş Yap
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <Icon icon="lucide:lock" class="mr-1" /> Forgot
                              your password?
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>© 2024 Reservation Track System.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>